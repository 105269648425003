import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/Article/articleLayout.js";
import Carousel from "../../../../components/Carousel";
import { graphql } from "gatsby";
import ArticleCarouselItem from "../../../../components/Article/articleCarouselItem";
export const pageQuery = graphql`
 {
  addax2020: allFile(filter: {relativeDirectory: {eq:"digital/print/addax-2020"}}, sort: {fields: name, order: ASC}){
      nodes{
        name
        childImageSharp{
          fluid(quality: 80, maxWidth: 1800){
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  addaxChristmas: allFile(filter: {relativeDirectory: {eq:"digital/print/addax-christmas"}}, sort: {fields: name, order: ASC}){
      nodes{
        name
        childImageSharp{
        fluid(quality: 80, maxWidth: 1800){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  joshuaDavid: allFile(filter: {relativeDirectory: {eq:"digital/print/joshua-david"}}, sort: {fields: name, order: ASC}){
    nodes{
      name
      childImageSharp{
      fluid(quality: 80, maxWidth: 1800){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  setupImages: allFile(filter: {relativeDirectory: {eq:"articles/handshake/setup"}}, sort: {fields: name, order: ASC}){
    nodes{
      name
      childImageSharp{
      fluid(quality: 80, maxWidth: 1800){
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
  messagesImage: file(relativePath: {eq:"articles/handshake/5.jpg"}){
      childImageSharp{
          fluid(quality: 80, maxWidth: 1800){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
 }
`;
export const _frontmatter = {};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Addax Petroleum 2020 Calendar`}</h1>
    <h2>{`I designed a sample calendar for Addax Petroleum. The theme of the design was “Historical Personalities” so I used some significant figures in Nigerian history. My style and colour choices were deliberate to make the design look vintage.`}</h2>
    <Carousel mdxType="Carousel">
  {props.data.addax2020.nodes.map(image => <ArticleCarouselItem fluid={image.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>
    <h1>{`Addax Petroleum Christmas Card`}</h1>
    <h2>{`These are some sample Christmas card designs I did for Addax Petroleum.`}</h2>
    <Carousel mdxType="Carousel">
  {props.data.addaxChristmas.nodes.map(image => <ArticleCarouselItem fluid={image.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>
    <h1>{`Joshua David Business Card`}</h1>
    <h2>{`Joshua David is a conductor, musicologist and instrumentalist. I did different business card design options for him that creatively capture what he does.`}</h2>
    <Carousel marginBottom={50} mdxType="Carousel">
  {props.data.joshuaDavid.nodes.map(image => <ArticleCarouselItem fluid={image.childImageSharp.fluid} mdxType="ArticleCarouselItem" />)}
    </Carousel>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      